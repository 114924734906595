
import { defineComponent } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "topbar",
    components: {
        KTUserMenu,
    },
    setup() {
        const i18n = useI18n();
        const setLocale = (locale) => {
            localStorage.locale = locale;
            i18n.locale = locale;
            window.location.reload();

        };
        const getLocaleLang = () => {
            return localStorage.locale;
        };
        return {
            i18n,
            setLocale,
            getLocaleLang,
        };
    },
});
