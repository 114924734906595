<template>
    <!--begin::Menu wrapper-->
    <div
        id="kt_aside_menu_wrapper"
        ref="scrollElRef"
        class="hover-scroll-overlay-y my-5 my-lg-5"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
        data-kt-scroll-height="auto"
        data-kt-scroll-offset="0"
        data-kt-scroll-wrappers="#kt_aside_menu"
    >
        <!--begin::Menu-->
        <div
            id="#kt_header_menu"
            class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
            data-kt-menu="true"
        >
            <div class="menu-item">
                <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/dashboard/">
                    <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                        <span class="menu-icon">
                            <i class="fas fa-home fs-3"></i>
                        </span>
                        <span class="menu-title">{{ $t("pages.dashboard.title") }}</span>
                    </a>
                </router-link>
            </div>
            <div class="menu-item mt-3">
                <div class="menu-content">
                    <div class="separator"></div>
                </div>
            </div>
            <div class="menu-item">
                <div class="menu-content pt-5 pb-2">
                    <span class="menu-section text-muted text-uppercase fs-8 ls-1"> {{ $t("layout.aside.managerSection") }} </span>
                </div>
            </div>
            <div
                v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('drive') !== -1"
                class="menu-item"
            >
                <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/drive/">
                    <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                        <span class="menu-icon">
                            <i class="fas fa-folder-open fs-3"></i>
                        </span>
                        <span class="menu-title">{{ $t("pages.dashboard.drive") }}</span>
                    </a>
                </router-link>
            </div>
            <div
                v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('attendance') !== -1"
                class="menu-item"
            >
                <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/attendance-monitoring/">
                    <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                        <span class="menu-icon">
                            <i class="fas fa-chart-line fs-3"></i>
                        </span>
                        <span class="menu-title">{{ $t("pages.dashboard.attendanceMonitoring") }}</span>
                    </a>
                </router-link>
            </div>
            <div
                v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('results') !== -1"
                class="menu-item"
            >
                <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/results/">
                    <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                        <span class="menu-icon">
                            <i class="fas fa-poll fs-3"></i>
                        </span>
                        <span class="menu-title">{{ $t("pages.dashboard.results") }}</span>
                    </a>
                </router-link>
            </div>
            <div
                v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('roadmap') !== -1"
                class="menu-item"
            >
                <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/roadmap/">
                    <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                        <span class="menu-icon">
                            <i class="fas fa-map-marked-alt fs-3"></i>
                        </span>
                        <span class="menu-title">{{ $t("pages.roadmap.title") }}</span>
                    </a>
                </router-link>
            </div>
            <div
                v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('ideas_box') !== -1"
                class="menu-item"
            >
                <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/ideas/">
                    <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                        <span class="menu-icon">
                            <i class="fas fa-lightbulb fs-3"></i>
                        </span>
                        <span class="menu-title">{{ $t("pages.ideas.title") }}</span>
                    </a>
                </router-link>
            </div>
            <div
                v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('useful-training') !== -1"
                class="menu-item"
            >
                <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/useful-training/">
                    <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                        <span class="menu-icon">
                            <i class="fas fa-chalkboard-teacher fs-3"></i>
                        </span>
                        <span class="menu-title">{{ $t("pages.usefulTraining.title") }}</span>
                    </a>
                </router-link>
            </div>
            <div
                v-if="this.$store.getters.currentUser.services.indexOf('survey') !== -1"
                class="menu-item"
            >
                <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/surveys/">
                    <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                        <span class="menu-icon">
                            <i class="fas fa-eye fs-3"></i>
                        </span>
                        <span class="menu-title">{{ $t("pages.surveys.title") }}</span>
                    </a>
                </router-link>
            </div>
            <template
                v-if="
                    this.$store.getters.currentUser.user.userprofile.user_type.position === 1 ||
                    this.$store.getters.currentUser.services.indexOf('users') !== -1 ||
                    this.$store.getters.currentUser.services.indexOf('entities') !== -1
                "
            >
                <div class="menu-item mt-3">
                    <div class="menu-content">
                        <div class="separator"></div>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-content pt-5 pb-2">
                        <span class="menu-section text-muted text-uppercase fs-8 ls-1"> {{ $t("layout.aside.respSection") }} </span>
                    </div>
                </div>
            </template>
            <div
                v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('users') !== -1"
                class="menu-item"
            >
                <div class="menu-item">
                    <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/users/">
                        <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                            <span class="menu-icon">
                                <i class="fas fa-users fs-3"></i>
                            </span>
                            <span class="menu-title">{{ $t("pages.dashboard.users") }}</span>
                        </a>
                    </router-link>
                </div>
                <div
                    v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('entities') !== -1"
                    class="menu-item"
                >
                    <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/entities/">
                        <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                            <span class="menu-icon">
                                <i class="fas fa-sitemap fs-3"></i>
                            </span>
                            <span class="menu-title">{{ $t("pages.dashboard.entities") }}</span>
                        </a>
                    </router-link>
                </div>
                <div
                    v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('notifications') !== -1"
                    class="menu-item"
                >
                    <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/notifications/">
                        <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                            <span class="menu-icon">
                                <i class="fas fa-bell fs-3"></i>
                            </span>
                            <span class="menu-title">{{ $t("pages.dashboard.notifications") }}</span>
                        </a>
                    </router-link>
                </div>
                <div
                    v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('statistics') !== -1"
                    class="menu-item menu-accordion"
                    data-kt-menu-sub="accordion"
                    data-kt-menu-trigger="click"
                >
                    <span class="menu-link">
                        <span class="menu-icon">
                            <i class="fas fa-chart-pie"></i>
                        </span>
                        <span class="menu-title">{{ $t("pages.statistics.title") }}</span>
                        <span class="menu-arrow"></span>
                    </span>

                    <div class="menu-sub menu-sub-accordion">
                        <div class="menu-item">
                            <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/statistics/users/">
                                <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">{{ $t("pages.userstatistics.title") }}</span>
                                </a>
                            </router-link>
                        </div>
                        <div class="menu-item">
                            <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/statistics/roadmaps/">
                                <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">{{ $t("pages.roadmapstatistics.title") }}</span>
                                </a>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="menu-item mt-3">
                <div class="menu-content">
                    <div class="separator"></div>
                </div>
            </div>
            <template v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1">
                <div class="menu-item">
                    <div class="menu-content pt-5 pb-2">
                        <span class="menu-section text-muted text-uppercase fs-8 ls-1"> {{ $t("layout.aside.admin") }} </span>
                    </div>
                </div>
                <div class="menu-item">
                    <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/administration/settings/">
                        <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                            <span class="menu-icon">
                                <i class="fas fa-cogs fs-3"></i>
                            </span>
                            <span class="menu-title">{{ $t("pages.settings.altTitle") }}</span>
                        </a>
                    </router-link>
                </div>
                <div class="menu-item">
                    <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/administration/access/">
                        <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                            <span class="menu-icon">
                                <i class="fas fa-universal-access fs-3"></i>
                            </span>
                            <span class="menu-title">{{ $t("pages.usersAccess.title") }}</span>
                        </a>
                    </router-link>
                </div>
                <div class="menu-item">
                    <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/administration/users/">
                        <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                            <span class="menu-icon">
                                <i class="fas fa-users-cog fs-3"></i>
                            </span>
                            <span class="menu-title">{{ $t("pages.dashboard.users") }}</span>
                        </a>
                    </router-link>
                </div>
                <div class="menu-item menu-accordion" data-kt-menu-sub="accordion" data-kt-menu-trigger="click">
                    <span class="menu-link">
                        <span class="menu-icon">
                            <i class="fas fa-file-signature fs-3"></i>
                        </span>
                        <span class="menu-title">{{ $t("layout.aside.docsManagement") }}</span>
                        <span class="menu-arrow"></span>
                    </span>

                    <div class="menu-sub menu-sub-accordion">
                        <div class="menu-item">
                            <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/administration/documents/documents/">
                                <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">{{ $t("pages.driveManagement.documents.title") }}</span>
                                </a>
                            </router-link>
                        </div>
                        <div class="menu-item">
                            <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/administration/documents/categories/">
                                <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">{{ $t("pages.driveManagement.categories.title") }}</span>
                                </a>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="menu-item">
                    <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/administration/roadmapcategory/">
                        <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                            <span class="menu-icon">
                                <i class="fas fa-map-marked-alt fs-3"></i>
                            </span>
                            <span class="menu-title">{{ $t("layout.aside.roadmapThemes") }}</span>
                        </a>
                    </router-link>
                </div>
                <div class="menu-item">
                    <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/administration/ideabox/">
                        <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                            <span class="menu-icon">
                                <i class="fas fa-lightbulb fs-3"></i>
                            </span>
                            <span class="menu-title">{{ $t("pages.ideas.title") }}</span>
                        </a>
                    </router-link>
                </div>
                <div class="menu-item">
                    <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/administration/usefultraining/">
                        <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                            <span class="menu-icon">
                                <i class="fas fa-chalkboard-teacher fs-3"></i>
                            </span>
                            <span class="menu-title">{{ $t("pages.usefulTraining.title") }}</span>
                        </a>
                    </router-link>
                </div>
                <div class="menu-item menu-accordion" data-kt-menu-sub="accordion" data-kt-menu-trigger="click">
                    <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/administration/results/">
                        <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                            <span class="menu-icon">
                                <i class="fas fa-solid fa-binoculars fs-3"></i>
                            </span>
                            <span class="menu-title">Résultats</span>
                        </a>
                    </router-link>
                </div>
                <div class="menu-item menu-accordion" data-kt-menu-sub="accordion" data-kt-menu-trigger="click">
                    <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/administration/campaigns/">
                        <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                            <span class="menu-icon">
                                <i class="fas fa-solid fa-binoculars fs-3"></i>
                            </span>
                            <span class="menu-title">Gestion des campagnes</span>
                        </a>
                    </router-link>
                </div>
                <div class="menu-item menu-accordion" data-kt-menu-sub="accordion" data-kt-menu-trigger="click">
                    <span class="menu-link">
                        <span class="menu-icon">
                            <i class="fas fa-eye fs-3"></i>
                        </span>
                        <span class="menu-title">{{ $t("layout.aside.surveys") }}</span>
                        <span class="menu-arrow"></span>
                    </span>

                    <div class="menu-sub menu-sub-accordion">
                        <div class="menu-item">
                            <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/administration/surveys/list/">
                                <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">{{ $t("pages.surveys.title") }}</span>
                                </a>
                            </router-link>
                        </div>
                        <div class="menu-item">
                            <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/administration/surveys/questions/">
                                <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">{{ $t("pages.surveys.questions.title") }}</span>
                                </a>
                            </router-link>
                        </div>
                        <div class="menu-item">
                            <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/administration/surveys/question-tags/">
                                <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">{{ $t("pages.surveys.questiontags.title") }}</span>
                                </a>
                            </router-link>
                        </div>
                        <div class="menu-item">
                            <router-link v-slot="{ href, navigate, isActive, isExactActive }" to="/administration/surveys/languages/">
                                <a :class="[isActive && 'active', isExactActive && 'active']" :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-bullet">
                                        <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">{{ $t("pages.surveys.language.title") }}</span>
                                </a>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-content">
                        <div class="separator mx-1 my-4"></div>
                    </div>
                </div>
            </template>
            <!--end::Menu-->
        </div>
        <!--end::Menu wrapper-->
    </div>
</template>

<style lang="scss">
.aside-menu .menu .menu-sub .menu-item a a.menu-link {
    padding-left: calc(0.75rem + 25px);
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    transition: none;
    outline: none !important;
}

.aside-menu .menu .menu-sub .menu-sub .menu-item a a.menu-link {
    padding-left: calc(1.5rem + 25px);
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    transition: none;
    outline: none !important;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";

export default defineComponent({
    name: "kt-menu",
    components: {},
    setup() {
        const { t, te } = useI18n();
        const route = useRoute();
        const scrollElRef = ref<null | HTMLElement>(null);

        onMounted(() => {
            ScrollComponent.reinitialization();
            MenuComponent.reinitialization();
            if (scrollElRef.value) {
                scrollElRef.value.scrollTop = 0;
            }
        });

        const translate = (text) => {
            if (te(text)) {
                return t(text);
            } else {
                return text;
            }
        };

        const hasActiveChildren = (match) => {
            return route.path.indexOf(match) !== -1;
        };

        return {
            hasActiveChildren,
            asideMenuIcons,
            version,
            translate,
        };
    },
});
</script>
