import ApiService from "@/core/services/ApiService";

const ADD_NOTIFICATION = "tools/create-notification/";
const GET_NOTIFICATIONS = "tools/get-notifications";
const GET_NOTIFICATION = "tools/get-notification/<pk>/";
const UPDATE_NOTIFICATION = "tools/update-notification/<pk>/";
const DELETE_NOTIFICATION = "tools/delete-notification/<pk>/";
const GET_USER_NOTIFICATIONS = "tools/get-user-notifications";
const CREATE_USER_NOTIFICATION = "tools/create-user-notification/";

export default {
    addNotification(notification) {
        ApiService.setHeader();
        return ApiService.post(ADD_NOTIFICATION, notification);
    },
    deleteNotification(notification_id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_NOTIFICATION.replace("<pk>", notification_id));
    },
    updateNotification(notification_id, notification) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_NOTIFICATION.replace("<pk>", notification_id), notification);
    },
    getNotifications() {
        ApiService.setHeader();
        return ApiService.get(GET_NOTIFICATIONS).then((response) => {
            return response.data;
        });
    },
    getNotification(notification_id) {
        ApiService.setHeader();
        return ApiService.get(GET_NOTIFICATION.replace("<pk>", notification_id)).then((response) => {
            return response.data;
        });
    },
    getUserNotifications() {
        ApiService.setHeader();
        return ApiService.get(GET_USER_NOTIFICATIONS).then((response) => {
            return response.data;
        });
    },
    addUserNotification(usernotification) {
        ApiService.setHeader();
        return ApiService.post(CREATE_USER_NOTIFICATION, usernotification).then((response) => {
            return response.data;
        });
    }
};
